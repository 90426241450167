import { useLocation, useNavigate } from '@remix-run/react'

export const useReloadWithSpinner = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (search = '') => {
    navigate(
      { pathname, search },
      { preventScrollReset: true, state: { withSpinner: true } },
    )
  }
}
